import React from 'react';

const Login = React.lazy(() => import('../views/Login'));
const OAuthCallback = React.lazy(() => import('../components/auth/OAuthCallback'));
const Email = React.lazy(() => import('../views/Email'));
const PhoneNumber = React.lazy(() => import('../views/PhoneNumber'));
const EnterTwoFACode = React.lazy(() => import('../views/EnterTwoFACode'));
const MoreOptions = React.lazy(() => import('../views/MoreOptions'));
const MyProfile = React.lazy(() => import('../views/MyProfile'));
const Birthday = React.lazy(() => import('../views/Birthday'));
const MakingMagic = React.lazy(() => import('../views/MakingMagic'));
const ReferralCode = React.lazy(() => import('../views/ReferralCode'));
const TermsAndConditions = React.lazy(() => import('../views/TermsAndConditions'));
const CTA = React.lazy(() => import('../views/CTA'));

const routes = [
  { path: '/login/my-profile', name: 'MyProfile', component: MyProfile },
  { path: '/login/terms-and-conditions', name: 'TermsAndConditions', component: TermsAndConditions },
  { path: '/login/phone-number', name: 'PhoneNumber', component: PhoneNumber },
  { path: '/login/email', name: 'Email', component: Email },
  { path: '/login/enter-twofa-code', name: 'EnterTwoFACode', component: EnterTwoFACode },
  { path: '/login/more-options', name: 'MoreOptions', component: MoreOptions },
  { path: '/login/birthday', name: 'Birthday', component: Birthday },
  // { path: '/login/making-magic', name: 'MakingMagic', component: MakingMagic },
  { path: '/login/referral-code', name: 'ReferralCode', component: ReferralCode },
  { path: '/callback', name: 'OAuthCallback', component: OAuthCallback },
  { path: '/login/call-to-action', name: 'CTA', component: CTA },
  { path: '/', name: 'Login', component: Login },
];
export default routes;
