import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import { authReducer } from './auth';
import { userReducer } from './user';
import { alertsReducer } from './alerts';

export const rootReducer = () =>
  combineReducers({
    auth: persistReducer({ key: 'auth', storage: storage }, authReducer),
    user: persistReducer({ key: 'user', storage: storageSession }, userReducer),
    alerts: alertsReducer,
  });

export default rootReducer;
