import { init as initFullStory, FullStory } from '@fullstory/browser';

const { FLUZ_FULLSTORY_ORG_ID } = process.env;

export const initFS = () => {
  if (FLUZ_FULLSTORY_ORG_ID) {
    initFullStory({ orgId: FLUZ_FULLSTORY_ORG_ID });
  }
};

export const setFSUser = (user_id: string, email: string, first_name: string, last_name: string) => {
  FullStory('setIdentity', {
    uid: user_id,
    properties: {
      email: email,
      displayName: [first_name, last_name].join(' '),
    },
  });
};

export const resetFSUser = () => {
  FullStory('setIdentity', { anonymous: true });
};

type UnknownObject = {
  [key: string]: unknown;
};

export const trackFSEvent = (name: string, properties?: UnknownObject) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`Calling FullStory trackEvent with: ${JSON.stringify({ name, properties })}`);
  }

  FullStory('trackEvent', {
    name,
    properties: properties ?? {},
  });
};
