export type AlertPosition = 'top-left' | 'top-center' | 'top-right';

export type AlertLevel = 'danger' | 'success' | 'warning' | 'info';

export enum AlertEnums {
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}
