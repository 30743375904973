export enum SignInMethod {
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE',
  PHONE = 'PHONE',
}

// Copied from common-sdk
export enum User2FAType {
  SMS = 'SMS',
  PHONE_CALL = 'CALL',
  TOTP = 'TOTP',
  WHATSAPP = 'WHATSAPP',
}

export enum User2FAProgram {
  TWILIO = 'TWILIO',
  TWILIO_VERIFY = 'TWILIO_VERIFY',
}

export enum User2FAReason {
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
}
