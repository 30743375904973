var api = require("!../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../node_modules/mini-css-extract-plugin/dist/loader.js??ref--5-oneOf-3-1!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-3-2!../node_modules/postcss-loader/dist/cjs.js??ref--5-oneOf-3-3!./App.css");

            content = content.__esModule ? content.default : content;

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};