import { SocialAuthCallbackResponse } from '../../library/interfaces';

export async function handleSocialAuthResponse(
  receivedUrl: string,
  intervalId?: NodeJS.Timeout,
  callback?: (data: SocialAuthCallbackResponse, closed: boolean) => void
) {
  const params = new URL(receivedUrl).searchParams;
  const socialAuthResponse: SocialAuthCallbackResponse = {
    success: params.has('token') ? true : false,
    token: params.has('token') ? (params.get('token') as string) : undefined,
    userDetails: params.has('userDetails') ? (params.get('userDetails') as string) : undefined,
    statusCode: params.has('statusCode') ? Number(params.get('statusCode')) : undefined,
    message: params.has('message') ? (params.get('message') as string) : undefined,
  };

  if (callback) {
    if (intervalId) {
      clearInterval(intervalId);
    }
    return callback(socialAuthResponse, false);
  }
}
