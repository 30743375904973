import { ActionType } from 'typesafe-actions';
import { SignInMethod } from '../../library/enums';
import * as user from './actions';
import {
  RESET_USER_STATE,
  SET_IS_NEW_USER,
  SET_EMAIL,
  SET_PHONE_NUMBER_DETAILS,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_DATE_OF_BIRTH,
  SET_GOOGLE_ID,
  SET_AVATAR_URL,
  SET_REFERRAL_CODE,
  SET_HAS_POWER_TAG,
  SET_SIGN_IN_METHOD,
  SET_REFERRED_LINK_PARAMS,
  SET_USER_STORE_STATE,
} from './constants';

export type UserAction = ActionType<typeof user>;

export type UserState = {
  isNewUser: boolean;
  userId: string;
  email: string;
  phoneNumberExpectedFormat: string;
  phoneNumberInput: string;
  phoneNumberFormatted: string;
  phoneNumberRegion: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  googleId: string;
  avatarUrl: string;
  referralCode: string;
  hasPowerTag: boolean;
  signInMethod: SignInMethod;
  referredLinkParams: any;
};

const defaultState: UserState = {
  userId: '',
  isNewUser: true,
  email: '',
  phoneNumberExpectedFormat: '',
  phoneNumberInput: '',
  phoneNumberFormatted: '',
  phoneNumberRegion: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  googleId: '',
  avatarUrl: '',
  referralCode: '',
  hasPowerTag: false,
  signInMethod: '' as SignInMethod,
  referredLinkParams: '',
};

export default (state = defaultState, action: UserAction): UserState => {
  switch (action.type) {
    case RESET_USER_STATE:
      return {
        ...defaultState,
        referralCode: state.referralCode, // do not reset referral code
        referredLinkParams: state.referredLinkParams, // do not reset referred link params
      };
    case SET_IS_NEW_USER:
      return {
        ...state,
        isNewUser: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case SET_LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      };
    case SET_DATE_OF_BIRTH:
      return {
        ...state,
        dateOfBirth: action.payload,
      };
    case SET_GOOGLE_ID:
      return {
        ...state,
        googleId: action.payload,
      };
    case SET_AVATAR_URL:
      return {
        ...state,
        avatarUrl: action.payload,
      };
    case SET_REFERRAL_CODE:
      return {
        ...state,
        referralCode: action.payload,
      };
    case SET_PHONE_NUMBER_DETAILS:
      return {
        ...state,
        phoneNumberExpectedFormat: action.payload.phoneNumberExpectedFormat,
        phoneNumberInput: action.payload.phoneNumberInput,
        phoneNumberFormatted: action.payload.phoneNumberFormatted,
        phoneNumberRegion: action.payload.phoneNumberRegion,
      };
    case SET_HAS_POWER_TAG:
      return {
        ...state,
        hasPowerTag: action.payload,
      };
    case SET_SIGN_IN_METHOD:
      return {
        ...state,
        signInMethod: action.payload,
      };

    case SET_REFERRED_LINK_PARAMS:
      return {
        ...state,
        referredLinkParams: action.payload,
      };

    case SET_USER_STORE_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
