import { createAlert } from '../../store/alerts/actions';
import { useDispatch } from 'react-redux';
import { ErrorMessages } from './error-messages';
import { AlertEnums, AlertLevel } from '../../library/enums';

const fallbackTitle = 'Error';
const fallbackMsg = "We're having trouble completing your request. Please try again later or contact support.";

export function useErrorHandler() {
  const dispatch = useDispatch();

  function showAlert(title: string, message: string, alertType: AlertLevel) {
    dispatch(
      createAlert({
        title,
        message,
        level: alertType,
      })
    );
  }

  function throwAlert(title?: string, message?: string, alertType?: AlertLevel) {
    const displayTitle = title ?? fallbackTitle;
    const displayMessage = message ?? fallbackMsg;
    const displayAlertType = alertType ?? AlertEnums.DANGER;

    showAlert(displayTitle, displayMessage, displayAlertType);
  }

  function handleError(error: any) {
    if (error?.name === 'HTTPError') {
      switch (error?.response.status) {
        case 498:
          throwAlert('Session Expired', 'Your session has expired due to inactivity. Please start over to continue.');
          break;
        // @TODO Add more default error messages
        default:
          throwAlert();
      }
    } else {
      throwAlert();
    }
  }

  return { throwAlert, showAlert, handleError };
}
