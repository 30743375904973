export const RESET_USER_STATE = 'user/RESET_USER_STATE';
export const SET_IS_NEW_USER = 'user/SET_IS_NEW_USER';
export const SET_EMAIL = 'user/SET_EMAIL';
export const SET_PHONE_NUMBER_DETAILS = 'user/SET_PHONE_NUMBER_DETAILS';
export const SET_FIRST_NAME = 'user/SET_FIRST_NAME';
export const SET_LAST_NAME = 'user/SET_LAST_NAME';
export const SET_DATE_OF_BIRTH = 'user/SET_DATE_OF_BIRTH';
export const SET_GOOGLE_ID = 'user/SET_GOOGLE_ID';
export const SET_AVATAR_URL = 'user/SET_AVATAR_URL';
export const SET_REFERRAL_CODE = 'user/SET_REFERRAL_CODE';
export const SET_HAS_POWER_TAG = 'user/SET_HAS_POWER_TAG';
export const SET_SIGN_IN_METHOD = 'user/SET_SIGN_IN_METHOD';
export const SET_REFERRED_LINK_PARAMS = 'user/SET_REFERRED_LINK_PARAMS';
export const SET_USER_STORE_STATE = 'user/SET_USER_STORE_STATE';
