import branch from 'branch-sdk';
const { FLUZ_BRANCH_KEY } = process.env;
export const branchController = async () => {
  return new Promise((resolve, reject) => {
    branch.init(FLUZ_BRANCH_KEY as string, {}, (error: any, data: any) => {
      if (error) {
        reject(error);
      } else {
        resolve(data?.data_parsed);
      }
    });
  });
};
