export function isEmailValid(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isNameValid(name: string) {
  const nameRegex = RegExp(/^([a-zA-Z]+\s*[a-zA-Z]+)+$/);
  return nameRegex.test(name.trim());
}

export function isUnder18YearsOld(birthDate: Date) {
  const currentDate = new Date();
  const providedDate = new Date(birthDate);
  let yearsDiff = currentDate.getFullYear() - providedDate.getFullYear();
  if (
    currentDate.getMonth() < providedDate.getMonth() ||
    (currentDate.getMonth() === providedDate.getMonth() && currentDate.getDate() < providedDate.getDate())
  ) {
    yearsDiff--;
  }
  return yearsDiff < 18;
}

export function isUserOlderThan18(birthDate: string) {
  const millisecondsIn18Years = 18 * 365 * 24 * 60 * 60 * 1000;
  const currentDate = new Date();
  const userBirthDate = new Date(birthDate);
  const ageDifference = currentDate.getTime() - userBirthDate.getTime();

  return ageDifference >= millisecondsIn18Years;
}

export function isDateAfter1900(inputDate: string) {
  const inputDateObj = new Date(inputDate);
  const inputYear = inputDateObj.getFullYear();
  return inputYear > 1900;
}
