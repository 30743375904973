import { action } from 'typesafe-actions';
import { SignInMethod } from '../../library/enums';

import {
  RESET_USER_STATE,
  SET_IS_NEW_USER,
  SET_EMAIL,
  SET_PHONE_NUMBER_DETAILS,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_DATE_OF_BIRTH,
  SET_GOOGLE_ID,
  SET_AVATAR_URL,
  SET_REFERRAL_CODE,
  SET_HAS_POWER_TAG,
  SET_SIGN_IN_METHOD,
  SET_REFERRED_LINK_PARAMS,
  SET_USER_STORE_STATE,
} from './constants';
import { UserState } from './reducer';

export const resetUser = () => action(RESET_USER_STATE);
export const setIsNewUser = (isNewUser: boolean) => action(SET_IS_NEW_USER, isNewUser);
export const setEmail = (email: string) => action(SET_EMAIL, email);
export const setFirstName = (firstName: string) => action(SET_FIRST_NAME, firstName);
export const setLastName = (lastName: string) => action(SET_LAST_NAME, lastName);
export const setDateOfBirth = (dateOfBirth: string) => action(SET_DATE_OF_BIRTH, dateOfBirth);
export const setGoogleId = (googleId: string) => action(SET_GOOGLE_ID, googleId);
export const setAvatarUrl = (avatarUrl: string) => action(SET_AVATAR_URL, avatarUrl);
export const setReferralCode = (referralCode: string) => action(SET_REFERRAL_CODE, referralCode);
export const setPhoneNumberDetails = (
  phoneNumberExpectedFormat: string,
  phoneNumberInput: string,
  phoneNumberFormatted: string,
  phoneNumberRegion: string
) =>
  action(SET_PHONE_NUMBER_DETAILS, {
    phoneNumberExpectedFormat,
    phoneNumberInput,
    phoneNumberFormatted,
    phoneNumberRegion,
  });
export const setHasPowerTag = (hasPowerTag: boolean) => action(SET_HAS_POWER_TAG, hasPowerTag);
export const setSignInMethod = (signInMethod: SignInMethod) => action(SET_SIGN_IN_METHOD, signInMethod);
export const setReferredLinkParams = (params: any) => action(SET_REFERRED_LINK_PARAMS, params);
export const setUser = (userState: Partial<UserState>) => action(SET_USER_STORE_STATE, userState);
